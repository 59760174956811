.Liste{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center top;
    justify-content:center;
    width: 100%;

    
}
.full{
    width: 100%;
}
.Sous-liste{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
}
.av_detail10{
    margin-bottom: -10px;
}
.arriving{
    text-decoration: underline;
}