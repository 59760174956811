.main{
    display:flex;
    flex-direction: column;
}
/* ---------------------------- */
.head{
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* ---------------------------- */
.body{
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    /*  */
    display: flex;
    flex-direction: column;
}
.body .welcome{
    text-align: center;
}
.row{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.column{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.prez{
    text-align: center;
}
.row .pic{
    max-width: 25%;
    margin: 5px; 
}
.pic-middle{
margin-left: auto;
   margin-right: auto;
}
@media only screen and (max-width: 600px) {
    .row .pic{
        width: 0%;
    }
  }

.bande{
    height: 150px;
    width: 100%;
    object-fit: cover;
}
.nav{
    display: flex;
    justify-content: center;
    width: 100%;
}
.contact {
    display: flex;
    color: rgb(52, 70, 71);
    flex-direction: row;
    align-items: center ;
}
.MuiLink-underlineNone{
    color: black;
}
.contact > h2, h3, a{
    margin: 10px;
    text-decoration: none;
}
/* ---------------------------- */
.footer{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-color: rgb(189, 214, 229);
}
.info{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content:center;
    
}
