.fond{
    background-image: url('./assets/fond.jpg');
    background-size: 150%;
    text-align: center;
}

.box{
    margin-top: 10px;
    margin-bottom: 10px;
}

.box_tarif{
    font-weight: bold;
}

.tarifs{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 10px;
    padding: 5px;
}

h4{
    margin: 0px;
}

.desc{
    margin-top: 10px;
}

.av_detail{
    margin-bottom: -20px;
}